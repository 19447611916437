<template>
    <div class="mt-3 stage-inner-container-padded">
        <b-row>
           <b-col cols="12" md="4" lg="3"  xl="2">
               <b-list-group>
                    <b-list-group-item href="#" v-for="(choice, index) in choice_list_items" :key="index" 
                        :active="index == activeIndex"
                        @click="loadChoice(choice, index)">
                        {{choice.choice_name_plural}}
                    </b-list-group-item>
                </b-list-group>
           </b-col>
           <b-col>
                <ChoiceApplet ref="vaApplet"></ChoiceApplet>
            </b-col> 
        </b-row>
    </div>
</template>

<script>

const axios = require('axios');
const instance = axios.create({ timeout: 10000, headers: {'Content-Type': 'application/json'}, withCredentials: true, crossdomain: true });
const uuidv4 = require('uuid/v4');
const butils = require('../libs/basicUtils.js'); 

import vaapplet from '@/views/applets/ChoicesApplet.vue'

export default {
    name: 'choice_lists',
    components: {
        'ChoiceApplet':  vaapplet
    },
    props:{
        choiceList: {
            type: String,
            default: () =>{ return null; }
        }
    },
    data(){
        return{
            choice_list_items: [],
            appletShow: false,
            scl: {},
            activeIndex: -1,
            lastChoice: null
        }
    },
    methods: {
        loadChoice(choice, index){
            if(choice == this.lastChoice){
                console.log(`No Change, Loaded Choice is The Same As Selected Choice`)
            }else{
                console.log('Load Choice');
                this.lastChoice = choice;
                this.activeIndex = index;
                var applet = {
                    api_path_prop: choice.endpoint_path_full,
                    singular: choice.choice_name_singular,
                    plural: choice.choice_name_plural,
                    visiblefields: choice.visible_fields_default
                };
                this.scl = applet;
                this.setTitleAndRouterPath(choice.endpoint_path, choice.choice_name_plural)
                this.$refs.vaApplet.setupChoiceList(applet);
            }
        },
        setTitleAndRouterPath(path, title){
            butils.updateWindowPathAndSetTitle(this, `/home/choice_lists${path}`, title);
            // document.title = `${title}`;
            // if(this.$router.currentRoute.path != `/home/choice_lists${path}`){
            //     this.$router.replace({ path: `/home/choice_lists${path}` })
            // }
        },
        fetchPageData () {
            //console.log(this)
            //Get User Token and Fetch The Values Required For This Page
            instance.get(process.env.VUE_APP_API_BASE_URL + '/lists/choice_lists.json')
                .then(async (response) => {
                    //console.log(response);
                    this.choice_list_items = response.data.result.records;
                    if(this.choiceList != null){
                        this.choice_list_items.forEach((cl, idx)=>{
                            if(cl.endpoint_path == `/${this.choiceList}`){
                                this.activeIndex = idx;
                            }
                        })
                    }
                    if(this.activeIndex != -1){
                        this.loadChoice(this.choice_list_items[this.activeIndex],this.activeIndex);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        openCreatorWindow(value) {
            this.$bvModal.show('create-new-choice-list-item')
        },
        createNewHoverStart(evt){
            evt.target.classList.add('add-link-hover')
        },
        createNewHoverEnd(evt){
            evt.target.classList.remove('add-link-hover')
        }
    },
    beforeMount (){
        document.title = `Choice Lists`;
        this.fetchPageData();
    }
}
</script>

<style>

</style>
